.td-pr {
    padding: 0 5px 0 0;
}

.td-pl {
    padding: 0 0 0 5px;
}

.box_wrap01 {
    /* border: 1px solid rgba(0,0,0,0.8); */
    border: 2px solid transparent !important;
    border-image: linear-gradient(to right, #d6b218, #ffe800) !important;
    border-image-slice: 1 !important;
    padding: 4px;
    float: left;
    width: 100%;
}

.box_box01 {

    background: rgba(0,0,0,0.5);
    float: left;
    width: 100%;
    padding: 10px 0 10px 0;
    text-align: center;
}

.box_font01 {
    float: left;
    width: 100%;
    line-height: 40px;
    font-weight: 600;
    color: #f9f9f9;
    padding: 0 0 0 0;
    letter-spacing: -1px;
}

.box_font02 {
    float: left;
    width: 100%;
    line-height: 20px;
    padding: 0 0 3px 0;
    color: #ffe25c;
    letter-spacing: -1px;
    font-weight: 900;
    /* font-family: Arial, sans-serif; */
    letter-spacing: -1pt;
}


/* ==================================
콤프 사용하기 부분
=======================================*/
.write_title_top {
    border-top: 1px solid #ffec91;
}

.write_title {
    background: rgba(0,0,0,0.7);
    border-bottom: 1px solid rgba(255,255,255,0.1);
    min-height: 60px;
    color: #ffffff;
    padding: 10px 20px 10px 20px;
    line-height: 21px;
    width: 180px;
    font-weight: 500;
}

.write_basic {
    background: rgba(0,0,0,0.5);
    border-bottom: 1px solid rgba(255,255,255,0.1);
    min-height: 60px;
    color: #ffffff;
    padding: 10px 20px 10px 20px;
    line-height: 21px;
}
