.popup-container {
    /* 팝업의 스크롤바 스타일링 */
    overflow-y: scroll;
    scrollbar-width: thin; /* Firefox에서 스크롤바를 스타일링하기 위한 속성 */
  }
  
  .popup-container::-webkit-scrollbar {
    width: 8px; /* 스크롤바 너비 */
  }
  .popup-container::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }
  .popup-container::-webkit-scrollbar-thumb {
    background-color:  #EDD185; /* 스크롤바 색상 */
  }

  .popup-container::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .game-container::-webkit-scrollbar {
    width: 3px; /* 스크롤바 너비 */
  }
  .game-container::-webkit-scrollbar-track {
    background-color: rgba(58, 59, 59 , 0.5);
  }
  .game-container::-webkit-scrollbar-thumb {
    /* 스크롤바 색상 */
    background-color: black !important;
      border-radius: 15px !important;
  }

  .game-container::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .main-container::-webkit-scrollbar {
    width: 6px; /* 스크롤바 너비 */
  }
  .main-container::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }
  .main-container::-webkit-scrollbar-thumb {
    background-color:  #EDD185 !important; /* 스크롤바 색상 */
  }

  .main-container::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .sports-container::-webkit-scrollbar {
    width: 3px; /* 스크롤바 너비 */
  }
  .sports-container::-webkit-scrollbar-track {
    background-color: rgba(58, 59, 59 , 0.5);
  }
  .sports-container::-webkit-scrollbar-thumb {
    /* 스크롤바 색상 */
    background-color: rgba(58, 59, 59 , 0.5) !important;
      border-radius: 15px !important;
  }

  .sports-container::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .sidebar-container::-webkit-scrollbar {
    width: 6px; /* 스크롤바 너비 */
    height: 6px;
  }
  .sidebar-container::-webkit-scrollbar-track {
    background-color: rgba(58, 59, 59 , 0.5);
  }
  .sidebar-container::-webkit-scrollbar-thumb {
    /* 스크롤바 색상 */
    transition: background-color .3s;
    background-color: rgba(144,147,153,.3);
      border-radius: 15px !important;
  }

  .sidebar-container::-webkit-scrollbar-thumb:hover {
    background: #555;
  }