
.toolbar {
    display: none !important;
}



.wrapper {
    width: 100%;
}
.DraftEditor-root{
    height: 30px;
    /* overflow-y: scroll !important; */
}

.BoardEditor {
    background-color: #c2c3c4;
    /* width: 610px; */
    width: 100%;
    height: 350px;
    /* overflow: hidden !important; */
    /* overflow-y: scroll !important; */
    padding-left: 10px;
}

.OneToOneEditor {
    background-color: #c2c3c4;
    /* width: 610px; */
    width: 100%;
    height: 200px;
    padding-left: 10px;
}

