/* 예: Quill 에디터의 내용 스타일을 변경 */
.ql-editor {
  font-size: 16px;
  line-height: 1.5;
  color: #000;
  border: none;
  height: 150px;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-top: 1px solid rgb(74, 74, 74);
}

.ql-container.ql-snow {
  border: 1px solid rgb(74, 74, 74);
}

.ql-toolbar {
  display: none;
}

 /* 플레이스홀더 텍스트 스타일 */
.ql-editor.ql-blank::before {
  font-style: normal;     
}